import { graphql, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const playerImage = graphql`
    fragment coachImage on File {
        childImageSharp {
            gatsbyImageData(width: 400, height: 400, layout: CONSTRAINED)
        }
    }
`

export const query = graphql`
    query {
        jim: file(relativePath: { eq: "coaches/Jim.png" }) {
            ...coachImage
        }
        breanna: file(relativePath: { eq: "coaches/Breanna.png" }) {
            ...coachImage
        }
        jordan: file(relativePath: { eq: "coaches/Jordan.png" }) {
            ...coachImage
        }
    }
`

interface Data {
    jim: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    breanna: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
    jordan: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
}

const Coaches: React.FC<PageProps<Data>> = ({ data }) => {
    return (
        <Layout>
            <SEO
                title="Coaches"
                description="Dedicated coaches to develop the players and to work closely with them through the college recruiting process."
            />
            <section className="hero is-dark mt-6">
                <div className="hero-body">
                    <div className="container">
                        <p className="title">CM Thunder Gold 18U Coaching Staff</p>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column" />
                        <div className="column is-four-fifths">
                            <article className="media">
                                <figure className="media-left is-hidden-mobile">
                                    <figure className="image is-128x128">
                                        <GatsbyImage image={data.jim.childImageSharp.gatsbyImageData} alt="Jim Shaffer" imgClassName="is-rounded" />
                                    </figure>
                                </figure>
                                <div className="media-content">
                                    <div className="content">
                                        <div className="columns is-hidden-tablet is-mobile is-centered">
                                            <div className="column is-half">
                                                <figure className="image is-128x128 is-hidden-tablet">
                                                    <GatsbyImage image={data.jim.childImageSharp.gatsbyImageData} alt="Jim Shaffer" imgClassName="is-rounded" />
                                                </figure>
                                            </div>
                                        </div>
                                        <p>
                                            <strong>Jim Shaffer</strong> <small>Head Coach</small>
                                            <br />
                                            Coach Shaffer has been coaching softball for 20 years. He has coached at every level
                                            10u to 18u creating a knowledgeable and competitive environment. Throughout the years
                                            he has been instrumental in creating strength and conditioning programs among each age
                                            level as well as recruitment opportunities at the advanced levels.
                                        </p>
                                    </div>
                                    <nav className="level is-mobile">
                                        <div className="level-left">
                                            <a className="level-item" href="mailto:shaffers12@aol.com">
                                                <span className="icon is-small">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </a>
                                            <a className="level-item" href="tel:5089891282">
                                                <span className="icon is-small">
                                                    <i className="fas fa-phone"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                            </article>
                            <article className="media mt-4">
                                <figure className="media-left is-hidden-mobile">
                                    <figure className="image is-128x128">
                                        <GatsbyImage image={data.breanna.childImageSharp.gatsbyImageData} alt="Breanna Shaffer" imgClassName="is-rounded" />
                                    </figure>
                                </figure>
                                <div className="media-content">
                                    <div className="content">
                                        <div className="columns is-hidden-tablet is-mobile is-centered">
                                            <div className="column is-half">
                                                <figure className="image is-128x128 is-hidden-tablet">
                                                    <GatsbyImage image={data.breanna.childImageSharp.gatsbyImageData} alt="Breanna Shaffer" imgClassName="is-rounded" />
                                                </figure>
                                            </div>
                                        </div>
                                        <p>
                                            <strong>Breanna Shaffer</strong> <small>Coach</small>
                                            <br />
                                            Coach Breanna graduated from King Philip Regional High School in 2013 where she was a
                                            four year player. Breanna has been a member of CMT as a player and now as an assistant
                                            coach. She went on to play at a collegiate level at Bridgewater State University as an
                                            outfielder winning a MASCAC Championship in the 2014 season. She graduated in 2017
                                            with a Bachelors Degree in Global Management.
                                        </p>
                                    </div>
                                    <nav className="level is-mobile">
                                        <div className="level-left">
                                            <a className="level-item" href="mailto:breshaffer37@gmail.com">
                                                <span className="icon is-small">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </a>
                                            <a className="level-item" href="tel:5088384930">
                                                <span className="icon is-small">
                                                    <i className="fas fa-phone"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                            </article>
                            <article className="media mt-4">
                                <figure className="media-left is-hidden-mobile">
                                    <figure className="image is-128x128">
                                        <GatsbyImage image={data.jordan.childImageSharp.gatsbyImageData} alt="Breanna Shaffer" imgClassName="is-rounded" />
                                    </figure>
                                </figure>
                                <div className="media-content">
                                    <div className="content">
                                        <div className="columns is-hidden-tablet is-mobile is-centered">
                                            <div className="column is-half">
                                                <figure className="image is-128x128 is-hidden-tablet">
                                                    <GatsbyImage image={data.jordan.childImageSharp.gatsbyImageData} alt="Breanna Shaffer" imgClassName="is-rounded" />
                                                </figure>
                                            </div>
                                        </div>
                                        <p>
                                            <strong>Jordan Shaffer</strong> <small>Coach</small>
                                            <br />
                                            Coach Jordan graduated from King Philip Regional High School in 2014 where she was a
                                            four year player. Jordan has been a member of CMT as a player and now as an assistant
                                            coach. She went on to play at a collegiate level at Assumption College as a catcher.
                                            She graduated in 2018 with a Bachelors degree in Psychology with a concentration in
                                            Occupational Therapy with a minor in Biology.
                                        </p>
                                    </div>
                                    <nav className="level is-mobile">
                                        <div className="level-left">
                                            <a className="level-item" href="mailto:joshaffer20@gmail.com">
                                                <span className="icon is-small">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                            </a>
                                            <a className="level-item" href="tel:5082694172">
                                                <span className="icon is-small">
                                                    <i className="fas fa-phone"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                            </article>
                        </div>
                        <div className="column" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Coaches
